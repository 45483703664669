import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './brocoders.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Blockquote from '../components/blockquote';
import Team from '../components/team';
import Resume from '../components/resume';
// other
import { meta } from '../../../metaData';

function BrocodersCase() {
  const data = useStaticQuery(graphql`
    query {
      rodionSalnik: file(relativePath: { eq: "rodion-salnik.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "brocoders-screen1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "brocoders-screen2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "brocoders-screen3.png" }) {
        childImageSharp {
          fluid(maxWidth: 405, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "brocoders-screen4.png" }) {
        childImageSharp {
          fluid(maxWidth: 413, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "brocoders-screen5.png" }) {
        childImageSharp {
          fluid(maxWidth: 413, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "brocoders-screen6.png" }) {
        childImageSharp {
          fluid(maxWidth: 406, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "brocoders-screen7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "brocoders-screen8.png" }) {
        childImageSharp {
          fluid(maxWidth: 413, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "brocoders-screen9.png" }) {
        childImageSharp {
          fluid(maxWidth: 414, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "brocoders-screen10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "brocoders-screen11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "brocoders-screen12.png" }) {
        childImageSharp {
          fluid(maxWidth: 912, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen13: file(relativePath: { eq: "brocoders-screen13.png" }) {
        childImageSharp {
          fluid(maxWidth: 912, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['IT', 'Ukraine', 'April, 2020', 'Ongoing']}
      navBtn="#D51762"
      layoutClassName={styles.main}
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Reinventing our old-hat company website"
      subTitle="We rebuilt the overall site architecture to enable a delightful digital experience for everyone who wants to dive into our stories."
      metaData={meta.brocoders}
    >
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The overview</h2>
        <p className={itemStyles.paragraph}>
          The old Brocoders website had been serving us faithfully since 2011. We liked its design
          for a while, and what is more, we were pleased with its quite decent performance. But as
          time passed, we've learned much during all these years and grown out of the legacy
          version, just like how children grow out of clothes.
        </p>
        <p className={itemStyles.paragraph}>
          That said, our team decided to renovate the whole site's structure so we could tell more
          about us and our services. It wasn't an easy call, and it took almost two months for us to
          turn up sleeves for the upcoming redesign process. We wanted to define our style, our
          brand, our voice and the emotions that we'd like to trigger once you visit our site.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          After much thought and discussion, as well as numerous design concepts we managed to
          review, we set a business mindset we'd want to stick to in years ahead. Our most crucial
          goal is to help those who somehow engage with our brand. Whether that be our customers or
          our own employees, we help both to grow and reach new frontiers.
        </p>
        <p className={itemStyles.paragraph}>
          We'd like you to find out what's happening behind the scenes throughout your trip on the
          pages of the site:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>How our specialists collaborate with clients and partners</li>
          <li>How our developers work</li>
          <li>The way our managers run multiple projects</li>
          <li>Which way QA experts check the quality of products</li>
          <li>How HRs manage the applicants' calls</li>
        </ul>
        <p className={itemStyles.preamble}>
          In this case study, we want to tell you about what we did and how we developed a new
          design for our website. We want you to become a part of our team to trust us with your
          project.
        </p>
        <h2 className={itemStyles.title}>Working process</h2>
        <p className={itemStyles.paragraph}>
          As a matter of priority, we started with a moodboard to eventually come up with inspiring
          and outstanding design patterns. It was vital for us to estimate the latest trends and
          find our own unique style. In fact, our proposals and requirements were quite simple: a
          light design, complex animations and a lot of colors.
        </p>
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Once it came to the design part, we decided to create prototypes to ensure the fascinating
          flexibility of the entire development process in the first place. These are simple layouts
          or also known as wireframes, where we managed to outline the ground-breaking structure of
          each page.
        </p>
      </div>
      <div className={styles.imgGroup}>
        <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screen3} alt="" />
        <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screen4} alt="" />
        <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen5} alt="" />
        <Img fluid={data.screen6.childImageSharp.fluid} className={styles.screen6} alt="" />
      </div>
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          It took a bunch of time for us to come up with an intuitive and visually-rich design of
          the main page since it should represent the company's image. Moreover, it is the first
          page you see when you visit the website. We had a few options to choose from until we
          picked the one for the current version.
        </p>
      </div>
      <Blockquote
        img={data.rodionSalnik.childImageSharp.fluid}
        text="“When we came up with the idea to redesign the Brocoders website, it was decided to opt for Strapi and Gatsby.js, which, in turn, allowed us to turbo-boost the project's management and development speed. These technologies also made it possible for us to fulfil the requirements for excellent quality, easier browsing, outstanding indexation by search engines and blazing-fast loading of pages.”"
        author={
          <React.Fragment>
            <b>Rodion Salnik</b>,
            <br />
            CEO &#10;Co-Founder at Brocoders
          </React.Fragment>
        }
        link={{ to: 'https://www.brocoders.com/', title: 'brocoders.com' }}
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          We explored the ins and outs of the technology market to reduce risks across the entire
          software development lifecycle. In the end, we were looking out for a powerful static site
          generator Gatsby.js. We conducted full-fledged research and analyzed a tremendous amount
          of data to ensure it wasn't just hype. Once all pros and cons were considered, we employed
          this React-based PWA generator.
        </p>
        <p className={itemStyles.paragraph}>
          Take a quick glance to find out features you can benefit from when using Gatsby.js:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            Powered by React and GraphQL, Gatsby allows for building websites at a quick pace. A
            component-based model makes it possible to reuse existing elements within the website's
            infrastructure, which, in turn, enhances the development speed and reduces
            time-to-market.
          </li>
          <li>
            Gatsby.js solutions are some of the fastest ones since Gatsby's ecosystem is empowered
            with up-to-date web standards and top-notch technologies, such as React.js, WebPack,
            GraphQL, JavaScript, CSS and ES6+.
          </li>
          <li>
            Gatsby.js shields you from numerous data breach vulnerabilities since it's separated
            from the CMS.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>Learn more about Gatsby.js development services</p>
        <p className={itemStyles.paragraph}>
          We considered all the details and recommendations and built a development plan to maximize
          our resources and avoid unplanned costs. Besides, we were filling the upcoming pages with
          content all the way through the development process.
        </p>

        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          We used the best of techs and our knowledge to cope with this challenging task. To
          leverage the full potential of Gatsby.js and acquire the most optimal and remarkable
          outcomes, we assembled a team of seasoned self-motivated experts. We addressed the core
          needs of our clients along with the world market trends to make sure we leave our visitors
          no option but to engage and return. The team was comprised of:
        </p>
        <Team
          color="var(--red-color)"
          list={[
            { count: 2, title: 'Frontend\ndevelopers' },
            { count: 1, title: 'Web\ndesigner' },
            { count: 1, title: 'Web\nContent Writer' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />
        <Resume
          services={['product', 'webDev', 'devOps']}
          technology={['gatsby', 'nodejs', 'figma']}
        />
      </div>

      <div className={itemStyles.inner}>
        <div className={styles.imgGroup2}>
          <Img fluid={data.screen13.childImageSharp.fluid} className={styles.screen13} alt="" />
          <Img fluid={data.screen12.childImageSharp.fluid} className={styles.screen12} alt="" />
        </div>
      </div>
      <Img fluid={data.screen11.childImageSharp.fluid} alt="" />
      <Img fluid={data.screen10.childImageSharp.fluid} alt="" />
      <div className={itemStyles.inner}>
        <div className={styles.imgGroup}>
          <Img fluid={data.screen8.childImageSharp.fluid} className={styles.screen3} alt="" />
          <Img fluid={data.screen9.childImageSharp.fluid} className={styles.screen4} alt="" />
        </div>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          The new website is empowered with a more search-friendly and socially-focused architecture
          to deliver significantly more excellent user experience, enhanced lead generation and
          improved content. The entire site is well-balanced by multiple animation components and
          provides intuitive navigation through all elements.
        </p>
        <p className={itemStyles.paragraph}>
          To date, you can see a 1.0 version that is not yet enriched with animations. We designed
          multiple complex animation elements that are currently in the development stage, but we
          were eager to showcase the work done.
        </p>
        <p className={itemStyles.paragraph}>
          Brocoders is meant to be a corporate website that is divided into several sectors:
          Services, Industries, Technologies, Cases and Blog. We used a standard functionality in
          the Blog sector ─ basically, it's a list of posts filtered by category and tag. Case
          studies are filtered by specific parameters as well. One of the key standout features of
          our site is that the backend is managed by Strapi, thus guaranteeing rock-solid security,
          impressive flexibility and easy adaption.
        </p>
        <p className={itemStyles.paragraph}>
          Currently, work is underway to integrate new features and functionality enhancements while
          implementing smooth animation features. The website is being prepared for the finale
          release and is already generating interest from potential partners.
        </p>
      </div>
      <Img fluid={data.screen7.childImageSharp.fluid} className={itemStyles.container} alt="" />
    </Layout>
  );
}

export default BrocodersCase;
